import React from 'react';

function Footer() {
    return (
        <footer className="footer" style={{ backgroundColor: 'white' }}>
            <div className="content has-text-centered">
                <p>
                    Body Calculation Hub © 2023.
                </p>
            </div>
        </footer>
    );
}

export default Footer;
