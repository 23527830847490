// middleware/languageUtils.js

export function getBrowserLanguage() {
    const lang = (navigator.language || navigator.userLanguage).split('-')[0];

    switch(lang) {
        case 'ca':
            return 'ca_ES';
        case 'es':
            return 'es_ES';
        case 'en':
            return 'en_EN';
        case 'fr':
            return 'fr_FR';
        case 'pt':
            return 'pt_BR';
        default:
            return 'en_EN'; // Valor predeterminado
    }
}

export function loadLanguageFile(langCode) {
    switch (langCode) {
        case 'ca_ES':
            return import('../i18n/ca_ES.json');
        case 'es_ES':
            return import('../i18n/es_ES.json');
        case 'en_EN':
            return import('../i18n/en_EN.json');
        case 'fr_FR':
            return import('../i18n/fr_FR.json');
        case 'pt_BR':
            return import('../i18n/pt_BR.json');
        default:
            return import('../i18n/en_EN.json'); // Carga inglés como predeterminado si no se encuentra el idioma
    }
}
