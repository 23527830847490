import React from 'react';
import CalculatorForm from './ccalories/CalculatorForm';

function Content() {
    return (
        <section className="section">
            <div className="container">
                <CalculatorForm />
            </div>
        </section>
    );
}

export default Content;
