import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import LanguageContext from './LanguageContext';
import { getBrowserLanguage, loadLanguageFile } from './middleware/languageUtils';
import 'bulma/css/bulma.min.css'
import Spinner from './components/loading/Spinner';

function Main() {
    const [languageData, setLanguageData] = useState(null);
    const langCode = getBrowserLanguage();

    useEffect(() => {
        loadLanguageFile(langCode).then(data => {
            setLanguageData(data.default);
        });
    }, [langCode]);

    if (!languageData) return <div style={{ height: '100vh', width: '100vw' }}>
        <Spinner />
    </div>;

    return (
        <LanguageContext.Provider value={languageData}>
            <Router>
                <App />
            </Router>
        </LanguageContext.Provider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);
