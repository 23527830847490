import React, { useContext, useEffect } from 'react';
import LanguageContext from './LanguageContext';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';


function App() {
  const languageData = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    const lang = languageData.seo.language;
    if (lang !== 'en') {
      navigate(`/${lang}`);
    }
  }, [languageData.seo.language, navigate]);

  return (
    <div>
      <Helmet>
        <html lang={languageData.seo.language} />
        <title>{languageData.seo.title}</title>
        <meta charset="utf-8" />
        <link rel="manifest" href="manifest.json" />
        <meta name="description" content={languageData.seo.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="favicon.ico" />
      </Helmet>
      <Header />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
