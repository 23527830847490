import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import LanguageContext from '../../LanguageContext';


function CalculatorForm() {
    const [gender, setGender] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [age, setAge] = useState('');
    const [activity, setActivity] = useState('sedentario');
    const [calories, setCalories] = useState(null);
    const [error, setError] = useState(null);
    const languageData = useContext(LanguageContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Verifica si todos los campos están llenos
        if (!gender || !weight || !height || !age || !activity) {
            setError(languageData.ccalorias.errors.general);
            return; // Sale de la función si no están completos
        }
        let tmb;
        if (gender === 'hombre') {
            tmb = 10 * weight + 6.25 * height - 5 * age + 5;
        } else {
            tmb = 10 * weight + 6.25 * height - 5 * age - 161;
        }


        const activityMultiplier = {
            sedentario: 1.2,
            ligero: 1.375,
            moderado: 1.55,
            activo: 1.725,
        };

        const dailyCalories = tmb * activityMultiplier[activity];
        setCalories(dailyCalories);
        setError(null); // Restablece el error
    };

    const activityDescriptions = {
        sedentario: languageData.ccalorias.fields.nivelactividad.options.sedentario.description,
        ligero: languageData.ccalorias.fields.nivelactividad.options.ligeractivo.description,
        moderado: languageData.ccalorias.fields.nivelactividad.options.moderactivo.description,
        activo: languageData.ccalorias.fields.nivelactividad.options.muyactivo.description,
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="container">
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <li className="is-active"><a href="#" aria-current="page">{languageData.menu.items.ccalorias}</a></li>
                </nav>
                <div className="field">
                    <label htmlFor="gender" className="label">{languageData.ccalorias.fields.genero.label}</label>
                    <div className="control">
                        <div className="select">
                            <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
                                <option value="">{languageData.ccalorias.fields.genero.placeholder}</option>
                                <option value="hombre">{languageData.ccalorias.fields.genero.options.hombre}</option>
                                <option value="mujer">{languageData.ccalorias.fields.genero.options.mujer}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="weight" className="label">{languageData.ccalorias.fields.peso}</label>
                    <div className="control">
                        <input type="number" id="weight" value={weight} onChange={(e) => setWeight(e.target.value)} className="input" />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="height" className="label">{languageData.ccalorias.fields.altura}</label>
                    <div className="control">
                        <input type="number" id="height" value={height} onChange={(e) => setHeight(e.target.value)} className="input" />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="age" className="label">{languageData.ccalorias.fields.edad}</label>
                    <div className="control">
                        <input type="number" id="age" value={age} onChange={(e) => setAge(e.target.value)} className="input" />
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="activity" className="label">{languageData.ccalorias.fields.nivelactividad.label}</label>
                    <div className="control">
                        <div className="select">
                            <select id="activity" value={activity} onChange={(e) => setActivity(e.target.value)}>
                                <option value="sedentario">{languageData.ccalorias.fields.nivelactividad.options.sedentario.label}</option>
                                <option value="ligero">{languageData.ccalorias.fields.nivelactividad.options.ligeractivo.label}</option>
                                <option value="moderado">{languageData.ccalorias.fields.nivelactividad.options.moderactivo.label}</option>
                                <option value="activo">{languageData.ccalorias.fields.nivelactividad.options.muyactivo.label}</option>
                            </select>
                        </div>
                    </div>
                    <div className="has-text-info">
                        <span className="icon">
                            <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '16px', width: '16px', height: '16px' }} />

                            {/* Icono de FontAwesome */}
                        </span>
                        <span>{activityDescriptions[activity]}</span>
                    </div>
                </div>
                {error && (
                    <div className="notification is-danger">
                        {error}
                    </div>
                )}
                <div className="control">
                    <button type="submit" className="button is-primary">
                        {languageData.ccalorias.actions.calcular}
                    </button>
                </div>
                <br />
                {calories && (
                    <div className="notification is-success">
                        <p className="subtitle">{languageData.ccalorias.results.label}</p>
                        <p>{languageData.ccalorias.results.calorias}<span className="has-text-weight-bold">{calories.toFixed(2)}</span></p>
                    </div>
                )}
            </form>
            <br />
            <div className="container">
                <div className="box">
                    <h3 className="title is-5">{languageData.ccalorias.footer.tfooter}</h3>
                    <p className="has-text-justified">
                        {languageData.ccalorias.footer.description}
                    </p>
                </div>
            </div>
        </>

    );
}

export default CalculatorForm;