// Spinner.js
import React from 'react';
import './Spinner.css';

function Spinner() {
    return (
        <div className="spinner-container">
            <div className="bubble bubble1"></div>
            <div className="bubble bubble2"></div>
            <div className="bubble bubble3"></div>
        </div>
    );
}

export default Spinner;
