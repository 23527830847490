import React, { useContext, useState } from 'react';
import LanguageContext from '../LanguageContext';
import logo from '../assets/images/bchub.svg'

function Header() {
    const languageData = useContext(LanguageContext);
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const handleLanguageChange = (e) => {
        console.log(e)
    }
    
    const getLanguageName = (code) => {
        const languageMap = {
            en: 'English',
            es: 'Español',
            ca: 'Català',
            pt: 'Português (Brasil)',
            fr: 'Français',
            de: 'Deutsch'
        };
        return languageMap[code] || code; // Devuelve el código si no se encuentra en el mapa.
    }


    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="https://bch-ak8.pages.dev/">
                    <img src={logo} width="45" height="28" alt="BCH Logo"></img>
                    <h2>{languageData.seo.title}</h2>
                </a>

                <a role="button" className={`navbar-burger ${isActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBCH" onClick={handleToggle}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBCH" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <a className={`navbar-item`}>
                        {languageData.menu.items.ccalorias}
                    </a>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            {languageData.menu.items.mas.label}
                        </a>

                        <div className="navbar-dropdown">
                            <a className="navbar-item">
                                {languageData.menu.items.mas.subitems.otros}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            {getLanguageName(languageData.seo.language)}
                        </a>
                        <div className="navbar-dropdown">
                            <a className={`navbar-item ${languageData.seo.language === 'en' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('en')}>
                                English
                            </a>
                            <a className={`navbar-item ${languageData.seo.language === 'es' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('es')}>
                                Español
                            </a>
                            <a className={`navbar-item ${languageData.seo.language === 'ca' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('ca')}>
                                Català
                            </a>
                            <a className={`navbar-item ${languageData.seo.language === 'pt' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('pt')}>
                                Português (Brasil)
                            </a>
                            <a className={`navbar-item ${languageData.seo.language === 'fr' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('fr')}>
                                Français
                            </a>
                            <a className={`navbar-item ${languageData.seo.language === 'de' ? 'is-active' : ''}`} onClick={() => handleLanguageChange('de')}>
                                Deutsch
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;
